.adress-wrapper {
  display: flex;
  flex-direction: column;
}

.adress-down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 4rem 1rem 4rem;
}

.adress-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.phone-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.adress-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.call-i {
  text-decoration: none;
  color: black;
}

.call-i:hover {
  color: wheat;
  transition: all 0.2s ease-in-out;
}

iframe {
  height: 450px;
  width: 600px;
}

@media screen and (max-width: 850px) {
  .adress-down {
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
  }
  .phone-div {
    font-size: small;
  }
  .adress-div {
    font-size: small;
    padding-bottom: 3rem;
  }

  iframe {
    height: 500px;
  }
}

@media screen and (max-width: 1055px) {
  iframe {
    width: 100%;
    height: 100%;
  }
}
