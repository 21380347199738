.container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../assets/404.png"), #151729;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

.container .content {
  max-width: 600px;
  text-align: center;
}

.container .content h2 {
  font-size: 18vw;
  color: #fff;
  line-height: 1em;
}

.container .content h4 {
  position: relative;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #111;
  background: #fff;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-block;
}

.container .content p {
  color: #fff;
  font-size: 1.2em;
}

.container .content a {
  position: relative;
  display: inline-block;
  padding: 10px 25px;
  background: #ff0562;
  color: #fff;
  text-decoration: none;
  margin-top: 25px;
  border-radius: 25px;
  border-bottom: 4px solid #d00d56;
}
