.form-container {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.form-container form {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.form-container input {
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
}

.form-container textarea {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
}

.form-container button {
  display: inline;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #151516;
  color: #fff;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 850px) {
  .form-container {
    margin: 4rem 2rem;
  }

  .form-container form {
    width: 90%;
    padding-top: 2rem;
  }
}
