.log-button {
  position: relative;
  display: inline-block;
  padding: 10px 25px;
  background: grey;
  color: #fff;
  text-decoration: none;
  margin-top: 25px;
  border-radius: 25px;
  border-bottom: 4px solid black;
}
.log-button:hover {
  color: black;
  background: wheat;
  transition: all 0.2s ease-in-out;
}
