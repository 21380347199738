.service-wrapper {
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: baseline;
}

.service-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  padding-bottom: 0rem;
}

.service-left {
  display: flex;
  flex-direction: column;
  padding-right: 1.5rem;
}

.service-links {
  font-size: 1rem;
  text-decoration: none;
  color: black;
}

.service-links:hover {
  color: wheat;
  transition: all 0.1s ease-in-out;
}

@media screen and (max-width: 850px) {
  .service-wrapper {
    flex-direction: column;
  }
  .service-left {
    padding-right: 1rem;
  }
}
