.comp {
  margin: 4rem 6rem;
  color: #2a2a2a;
}
.comp-wrapper {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comp h1 {
  font-size: 3rem;
}

.compcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.comp-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
  text-decoration: none;
  color: #2a2a2a;
}

.comp-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}

.comp-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

.comp-image:hover img {
  transform: scale(1.3);
}

.comp-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}

@media screen and (max-width: 850px) {
  .comp {
    margin: 4rem 2rem;
  }

  .compcard {
    flex-direction: column;
  }
  .comp-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
