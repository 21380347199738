.comp-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 80%;
  margin-bottom: 2rem;
}

.comp-button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.comp-button:hover {
  background-color: #151516;
  color: #fff;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1270px) {
  .comp-button-div {
    padding-left: 75%;
  }
}

@media screen and (max-width: 900px) {
  .comp-button-div {
    padding-left: 65%;
  }
}

@media screen and (max-width: 850px) {
  .comp-button-div {
    padding-left: 0%;
  }
}
